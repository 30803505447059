const EmailInspirationCategories = [{
    id:'welcome-email',
    text: '🙏 Welcome emails',
    simpleText: 'Welcome emails',
    airtableId: 'Welcome email'
}, {
    id:'activation-email',
    text: '✅ Activation emails',
    simpleText: 'Activation emails',
    airtableId: 'Activation Email'
}]


module.exports = { 
    EmailInspirationCategories: EmailInspirationCategories
}
