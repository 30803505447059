import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../../components/layout";
import { EmailInspirationCategories } from "../../constants";
import SEO from "../../components/seo";






function convertToSlug(Text)
  {
      return Text
          .toString()
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          ;
  }




const ComponentName = () => {

    const data = useStaticQuery(graphql`
    {
        posts: allAirtable(
            sort: {fields: data___publishedDate, order: DESC}
            filter: {table: {eq: "Emails"}}) {
          edges {
            node {
              id
              data {
                Name
                Status
                Name__from_Companies_
                Subject
                website
                website__from_Companies_
                Emails
                EmailType
                Company
                Attachments {
                    filename
                    id
                    url
                  }
              }
            }
          }
        }
        companies: allAirtable(filter: {table: {eq: "Companies"}}) {
          edges {
            node {
              id
              data {
                Name
                website
              }
            }
          }
        }


        categories: allAirtable(filter: { table: { eq: "Categories" } }) {
            edges {
                node {
                    id
                    data {
                        Name
                        AirtableId
                        SimpleText
                    }
                }
            }
        }


        Allcompanies: allAirtable(filter: {table: { eq: "Companies" }}) {
            edges {
                node {
                    id
                    data {
                        Name
                        pageHeading
                    }
                }
            }
        }

      }
    `)

    const posts = data.posts;
    const companies = data.companies;
    const categories = data.categories;
    return (
        <Layout logoText="Inspirations">
            <SEO 
                    description={`Handpicked email design gallery. Emailkick inpsiration is to help developers and designer create good looking emails for their next product.`}
                    title={`Handpicked email designs created by software companies`} 
                    author="Athul Suresh"
                />

            <div className="mt-12  py-16 border-b border-gray-200">
                <div className="max-w-6xl mx-auto text-center">
                    <h1 className="font-bold text-red-750 px-4">Email inspiration for your products</h1>
                    <p className="mt-2 text-base text-gray-500 px-4">Find designs and copy inspirations for your next email send to your customers</p>
                </div>
            </div>

            <div className="pt-8 md:py-12 bg-gray-50">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-base text-gray-500 ">Filter based on categories</div>
                    <div className="flex flex-wrap">
                        {categories.edges.map(({node}) => (
                            <a key={node.id} href={`/email-inspirations/category/${node.data.AirtableId}/`} className="px-4 mt-2 py-2 mr-4 duration-75 bg-white  hover:shadow hover:border-gray-300 text-center shadow-sm border borde-gray-200 rounded-lg text-sm font-normal text-gray-700">
                            {node.data.Name}
                            </a>
                        ))} 
                    </div>
                </div>
               
            </div>

            

            <div className="py-12 bg-gray-50">
                <div className="max-w-7xl mx-auto">

                    <h2 className="mb-8 px-4 text-base text-gray-600 ">Latest designs</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2  gap-2 md:gap-8 px-4">

                        { posts.edges.filter(a => a.node.data.Subject != null ).map(epost => (
                            <div>
                            <Link to={`/email-inspirations/${convertToSlug(epost.node.data.Name__from_Companies_)}/${convertToSlug(epost.node.data.Subject)}/`} key={epost.node.id} className="block rounded-lg border border-gray-200 shadow-sm bg-white duration-75 transform scale-100 hover:scale-105 hover:shadow-lg mb-4">
                                {epost.node.data.Attachments ? <img src={epost.node.data.Attachments[0].url} style={{ maxHeight:'350px' }} className="w-full object-cover object-top border-b border-gray-100 rounded-t-md"></img> : null }
                                <div className="px-2 py-3">
                                    <div className="text-sm text-gray-800 leading-relaxed">{epost.node.data.Subject}</div>
                                    <div className="text-gray-600 mt-2">By {epost.node.data.Name__from_Companies_ ? epost.node.data.Name__from_Companies_[0] : null}</div>
                                </div>
                            </Link>
                            </div>
                        ))}

                       
                    </div>
                </div>
                
            </div>

            <div className="py-12">
                <div className="max-w-6xl mx-auto px-4 md:px-0">
                    <h2 className="text-center text-base mb-8 text-gray-500">Find great email design by these companies</h2>
                    <div className="flex items-center justify-center flex-wrap mt-4">
                        { data.Allcompanies.edges.map(({node}) => (
                            <Link className="text-sm px-4 py-2 mb-4 bg-gray-50 text-gray-700 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-300 border border-gray-200 rounded-full mr-2" to={`/email-inspirations/${convertToSlug(node.data.Name)}/`}>{node.data.pageHeading ? node.data.pageHeading : 'Good looking emails'} from <span className="underline">{node.data.Name}</span></Link>
                        ))}
                    </div>
                </div> 
            </div>

            
        </Layout>
    )
}



// export const query = graphql`
// {
//     posts: allAirtable(filter: {table: {eq: "Emails"}}) {
//       edges {
//         node {
//           id
//           data {
//             Name
//             Status
//             Name__from_Companies_
//             Subject
//             website
//             website__from_Companies_
//             Emails
//             EmailType
//             Company
//           }
//         }
//       }
//     }
//     companies: allAirtable(filter: {table: {eq: "Companies"}}) {
//       edges {
//         node {
//           id
//           data {
//             Name
//             website
//           }
//         }
//       }
//     }
//   }
// `

export default ComponentName